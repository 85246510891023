<template>
    <v-container style="max-width:100vw;">
        <!-- Filtros -->
        <v-navigation-drawer style="top: 0px; max-height:100vh!important;" right v-model="filters" :clipped="$vuetify.breakpoint.lgAndUp" app>
            <filterExpense @filtersExpense="filtersExpense"/>
        </v-navigation-drawer>
        <!-- Contenedor -->
        <v-data-table :headers="headers" :items="expenses" class="elevation-0 px-6 py-4">
            <!-- Header -->
            <template v-slot:top>
                <v-toolbar flat >
                    <v-toolbar-title>Gastos</v-toolbar-title>
                    <v-btn icon>
                        <v-icon @click="openFilter">mdi-filter</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn icon v-show="permissions('download')">
                        <v-icon @click="exportExcel">mdi-download</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>



            <!-- Footer -->
            <template v-slot:footer>
                <v-container style="margin-bottom:-67px!important;">
                    <v-select :items="sumavg" v-model="totalType" style="width: 100px; display: inline-block; margin-right:30px; font-size: 14px;"></v-select>
                    <v-select :items="campos" v-model="campo" item-text="text" item-value="value" style="width: 100px; display: inline-block; margin-right:30px; font-size: 14px;"></v-select>
                    <strong v-if="totalType=='sum'">Total = {{ sumField(campo) }}</strong>
                    <strong v-if="totalType=='avg'">Promedio = {{ avgField(campo) }}</strong>
                </v-container>
            </template> 

            <template v-slot:[`item.amount`]="{ item }">
                {{item.amount.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})}}
            </template>



            <!-- Acciones por fila -->
            <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom left v-if="permissions('editExpenses')==true || permissions('deleteActivities')==true">
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                    </template>
                    <v-list style="font-size:13px;">
                        <v-list-item @click="editItem(item)" v-show="permissions('editActivities')">
                            <v-icon small class="mr-2">
                                mdi-pencil
                            </v-icon>
                            Editar
                        </v-list-item>
                        <v-list-item @click="deleteItem(item)" v-show="permissions('deleteActivities')">
                            <v-icon small class="mr-2">
                                mdi-delete
                            </v-icon>
                            Eliminar
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
            <!-- Creación -->
            <template v-slot:[`item.paid`]="{ item }">
                <v-icon v-if="item.paid==true" color="green">mdi-cash-check</v-icon>
                <v-icon v-if="item.paid==false" color="red">mdi-cash-remove</v-icon>
            </template>
            <!-- Creación -->
            <template v-slot:[`item.pdf`]="{ item }">
                <v-btn v-bind:href="'https://intenbackend.unocrm.mx/files/' + item.pdf" target="_blank" icon>
                    <v-icon v-if="item.pdf!=undefined">mdi-download-circle</v-icon>
                </v-btn>
            </template>
            <!-- Tabla sin información -->
            <template v-slot:no-data>
                No existen registros de gastos aún
            </template>
        </v-data-table>
        <!-- Crear gasto -->
        <v-dialog v-model="createDialog" max-width="900px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn bottom color="#e74919" dark fab fixed right v-bind="attrs" v-on="on">
                <v-icon color="white">  mdi-plus </v-icon>
            </v-btn> 
          </template>
          <create @closeDialogCreateExpense="closeDialogCreateExpense"/>
        </v-dialog>
        <!-- Editar gasto -->
        <v-dialog v-model="editDialog" max-width="900px">
          <edit v-bind:editedItem="expense" @closeDialogEditExpense="closeDialogEditExpense"/>
        </v-dialog>
        <!-- Dialogo confirmación -->
        <div class="text-center">
            <v-bottom-sheet  v-model="sheet" inset>
                <v-sheet class="text-center" height="150px">
                    <div class="pt-6">
                    ¿Seguro que deseas borrar este gasto?
                    </div>
                    <v-btn class="mt-4" text color="error" @click="deleteExpense()">
                    Eliminar
                    </v-btn>
                    <v-btn class="mt-4" text color="grey" @click="cancel()">
                    Cancelar
                    </v-btn>
                </v-sheet>
            </v-bottom-sheet>
        </div>
        <v-snackbar :color="snackbar.color" v-model="snackbar.show">
            {{ snackbar.message }}
        </v-snackbar>
    </v-container>
</template>

<script>
import axios from "axios";
import XLSX from 'xlsx';
import FilterExpense from "../expenses/filter"
import Create from "../expenses/create"
import Edit from "../expenses/edit"
export default {
    components: {
        'filterExpense':FilterExpense,
        'create':Create,
        'edit':Edit,
    }, 
    data: () => ({
        campo:'amount',
        campos:[
            { text: 'Monto', value: 'amount' },
        ],
        totalType:'sum',
        sumavg:['sum', 'avg'],
        expense:'',
        sheet: false,
        filters: false,
        editDialog: false,
        createDialog: false,
        deleteId:'',
        expenses: '',
        snackbar: {
            show: false,
            message: null,
            color: null
        },
    }),
    computed: {
        currentUser:{
            get(){
                return this.$store.state.currentUser.user;
            }
        },
        headers(){ 
            this.expenses = this.expensesLists
            return [
            { text: 'Concepto', value: 'concept' },
            { text: 'Tipo de Gasto', value: 'type' },
            { text: 'Proveedor', value: 'provider_id' },
            { text: 'Serie', value: 'serie' },
            { text: 'Metodo de Pago', value: 'payment_method_id' },
            { text: 'Monto', value: 'amount' },
            { text: 'Fecha', value: 'date' },
            { text: 'Factura', value: 'invoice' },
            { text: 'Vencimiento', value: 'due_date' },
            { text: 'Pago', value: 'payment_date' },
            { text: 'Pagada', value: 'paid' },
            { text: 'Notas', value: 'notes' },
            { text: 'PDF', value: 'pdf' },
            { text: 'Creación', value: 'created_at' },
            { text: 'Creador', value: 'created_by_user_id' },
            { text: 'Edición', value: 'updated_at' },
            { text: 'Editor', value: 'last_updated_by_user_id' },
            { value: 'actions', sortable: false, align: 'end', },
        ]},
        expensesLists(){
            return this.$store.state.expense.expenses.map(id=>{
                return{
                    id:id.id,
                    concept: id.concept,
                    type: this.expenseType(id.type),
                    provider_id: this.provider(id.provider_id),
                    serie: id.serie,
                    payment_method_id: this.method(id.payment_method_id),
                    amount: id.amount,//.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',}),
                    date: id.date,
                    invoice: id.invoice,
                    due_date: id.due_date,
                    payment_date: id.payment_date,
                    paid: id.paid,
                    notes: id.notes,
                    pdf: id.pdf,
                    created_by_user_id: this.salesman(id.created_by_user_id),
                    last_updated_by_user_id: this.salesman(id.last_updated_by_user_id),
                    created_at: id.created_at.slice(0, 10),
                    updated_at: id.updated_at.slice(0, 10)
                }
            });
        },
    },
    created () {
        this.expenses = this.expensesLists   
    },
    methods: {
        sumField(key) {//suma
            // sum data in give key (property)
            var suma=this.expensesLists.reduce((a, b) => a + (b[key] || 0), 0)
            return suma.toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})
        },
        avgField(key){//primedio
            var suma=this.expensesLists.reduce((a, b) => a + (b[key] || 0), 0)
            return (suma/this.expensesLists.length).toLocaleString('es-MX', { style: 'currency', currency: 'MXN',})
        },
        salesman(id){
            return this.$store.state.user.users.filter(user=>user.id == id).map(user=>user.name + ' ' + user.last)
        },
        method(id){
            return this.$store.state.payment_method.payment_methods.filter(method=>method.id == id).map(method=>method.method)[0]
        },
        provider(id){
            return this.$store.state.provider.providers.filter(provider=>provider.id == id).map(provider=>provider.name)[0]
        },
        expenseType(id){
            return this.$store.state.expense_type.expense_types.filter(type=>type.id == id).map(type=>type.name)[0]
        },
        lowerCase(text){
            if(text!=null&&text!=undefined&&text!=''){
                return text.toLowerCase()
            }else{
                return ' '
            }
        },
        openFilter(){
            if(this.filters == false){
                this.$emit("closeDrawer", false);
                this.filters = true
            }else{
                this.$emit("closeDrawer", true);
                this.filters = false
            }
        },
        permissions(permission){
        if(this.currentUser.id==1){
          return true
        }else if(this.currentUser.permissions!=undefined){
          if(this.currentUser.permissions.includes(permission)){
            return true
          }else{
            return false
          }
        }else{
          return false
        }
      },
        removeDuplicates(originalArray, prop) {
            var newArray = [];
            var lookupObject  = {};
            for(var i in originalArray) {
                lookupObject[originalArray[i][prop]] = originalArray[i];
            }
            for(i in lookupObject) {
                newArray.push(lookupObject[i]);
            }
            return newArray;
        },
        filtersExpense: function(params) {
            this.expenses = ''
            var filterExpense = this.$store.state.expense.expenses
    
            this.expenses = filterExpense/*.map(id=>{
                return{
                    id:id.id,
                    companyID:id.company_id,
                    company:this.companyName(id.company_id),
                    contact:this.contact(id.contact_id),
                    expense:this.expense(id.expense_id),
                    salesman:this.salesman(id.company_id),
                    date:id.date,
                    description:id.description,
                    completed:id.completed,
                    created_at:id.created_at,
                    updated_at:id.updated_at,
                }
            });*/
        },
        closeDialogEditExpense: function(params) {
            this.editDialog = false;
            this.$store.dispatch('expense/getExpenses')
        },
        closeDialogCreateExpense: function(params) {
            this.createDialog = false;
            this.$store.dispatch('expense/getExpenses')
        },
        exportExcel: function () {
            let data = XLSX.utils.json_to_sheet(this.expenses)
            const workbook = XLSX.utils.book_new()
            const filename = 'Lista de Actividades'
            XLSX.utils.book_append_sheet(workbook, data, filename)
            XLSX.writeFile(workbook, `${filename}.xlsx`)
        },
        deleteExpense(){
            axios.delete("https://intenbackend.unocrm.mx/api/v1/expense/delete/"+this.deleteId).then(response => {
                this.deleteId = ''
                this.sheet = false
                this.$store.dispatch('expense/getExpenses')
            }).catch(error => {
                this.snackbar = {
                    message: error.response.data.message,
                    color: 'error',
                    show: true
                }
            });
        },
        cancel(){
            this.deleteId = ''
            this.sheet = false
        },
        deleteItem (item) {
            this.deleteId = item.id
            this.sheet = true
        },
        editItem(item){
            this.expense = this.$store.state.expense.expenses.filter(expense=>expense.id == item.id)[0]
            this.editDialog = true
        },
    }
}
</script>


<style>
    .bordercolor{
        width: 3px;
        content: ' ';
        height: 47px;
        position: absolute;
        border-radius:5px 0px 0px 5px;
    }
    @media(min-width: 980px){
        .bordercolor{
            margin-top: -13px;
            margin-left: -19px;
        }
    }
    @media(max-width: 980px){
        .bordercolor {
            height: 360px;
            left: 9px;
        }
    }
</style>